<template>
  <div>
    <!-- 列表搜索 -->
    <div class="search-list">
      <el-form inline :model="queryForm">
        <el-form-item label="模糊查询">
          <el-input v-model="queryForm.searchValue" placeholder="请输入设备名称/型号" style="width:280px"></el-input>
        </el-form-item>
        <el-form-item label="所属项目部">
          <el-select v-model="queryForm.projectDepartmentList" placeholder="请选择所属项目部" multiple collapse-tags>
            <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handelSearch">查询</el-button>
          <el-button @click="handelReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 操作按钮 -->
    <div class="operate-grounp">
      <el-button
        type="primary"
        icon="el-icon-plus"
        class="add-btn"
        @click="handelAddDevice"
        v-if="$check('device-manage_add')"
        >新增设备</el-button
      >
      <el-button
        v-if="$check('device-manage_export')"
        class="export-btn"
        @click="handleExport"
        :disabled="btnDisabeld"
        v-loading.fullscreen="exportLoading"
        element-loading-text="拼命导出中"
        element-loading-background="rgba(0, 0, 0, 0.1)"
        >导出</el-button
      >
    </div>
    <!-- 列表 -->
    <div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        stripe
        style="width: 100%"
        v-loading="tabelLoading"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed align="center"> </el-table-column>
        <el-table-column label="序号" width="55" fixed align="center">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="projectDepartmentName"
          label="所属项目部"
          fixed
          align="center"
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column prop="model" label="型号" align="center" show-overflow-tooltip sortable> </el-table-column>
        <el-table-column prop="useDate" label="使用日期" align="center" sortable>
          <template slot-scope="{ row }">
            <span>{{ row.useDate.substr(0, 11) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center">
          <template slot-scope="{ row }">
            <span class="action-btn" @click="handelDetail(row.id)">查看详情</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="queryForm.current"
        :page-size="queryForm.size"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <add-device-dialog
      ref="addDeviceRef"
      :dialog-title.sync="dialogTitle"
      :dialog-visible.sync="dialogVisible"
      :is-read.sync="isRead"
      :project-list="projectList"
      @handel-success="handelSuccess"
    ></add-device-dialog>
  </div>
</template>

<script>
import { exportFun } from '@/utils/export.js';
import { getProjectDepartmentList } from 'api/common';
import { getDeviceList, exportInstallation } from 'api/general-user/equip-facilities';
import addDeviceDialog from './addDeviceDialog';
export default {
  components: {
    addDeviceDialog
  },
  data() {
    return {
      tabelLoading: false,
      queryForm: {
        projectDepartmentList: [],
        searchValue: '',
        current: 1,
        size: 10
      },
      total: 0,
      projectList: [],
      tableData: [],
      //dialog数据
      dialogTitle: '',
      dialogVisible: false,
      isRead: false,
      //导出需要的idList
      exportLoading: false,
      idList: [],
      btnDisabeld: true
    };
  },
  methods: {
    handleSelectionChange(row) {
      this.idList = [];
      row.forEach(item => {
        this.idList.push(item.id);
      });
      this.btnDisabeld = !Boolean(this.idList.length);
    },
    //导出
    handleExport() {
      this.exportLoading = true;
      exportInstallation({
        idList: this.idList
      })
        .then(res => {
          if (res.status === 200) {
            this.exportLoading = false;
            exportFun(res);
            this.$message({
              type: 'success',
              message: '导出数据成功',
              onClose: () => {
                this.$refs.multipleTable.clearSelection();
              }
            });
          }
        })
        .catch(() => {
          this.exportLoading = false;
          this.$refs.multipleTable.clearSelection();
        });
    },
    handelSuccess(boolean) {
      if (boolean) {
        this.queryForm.current = 1;
      }
      this.getDeviceList();
    },
    handelAddDevice() {
      this.dialogTitle = '新增设备';
      this.dialogVisible = true;
    },
    handelDetail(id) {
      this.$refs.addDeviceRef.initFormDetail(id);
      this.isRead = true;
      this.dialogTitle = '查看详情';
      this.dialogVisible = true;
    },
    handelSearch() {
      this.queryForm.current = 1;
      this.queryForm.size = 10;
      this.getDeviceList();
    },
    handelReset() {
      this.queryForm = {};
      this.queryForm.current = 1;
      this.queryForm.size = 10;
      this.$refs.multipleTable.clearSort();
      this.getDeviceList();
    },
    handleSizeChange(size) {
      this.queryForm.current = 1;
      this.queryForm.size = size;
      this.getDeviceList();
    },
    handleCurrentChange(current) {
      this.queryForm.current = current;
      this.getDeviceList();
    },
    getDeviceList() {
      //分页数据
      this.tabelLoading = true;
      getDeviceList(this.queryForm).then(res => {
        if (res.code === 200) {
          this.tabelLoading = false;
          const { records, total } = res.data;
          this.tableData = records;
          this.total = total;
        }
      });
    },
    getProjectDepartmentList() {
      //这个单独接口获取(所属项目部)
      getProjectDepartmentList().then(res => {
        if (res.code === 200) {
          let { data } = res;
          this.projectList = data;
        }
      });
    }
  },
  created() {
    this.getProjectDepartmentList();
    this.getDeviceList();
  }
};
</script>

<style scoped>
.operate-grounp {
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
