import request from '@/utils/request.js';
/**
 * 装备设施列表获取
 * @returns {Promise}
 */
export function getDeviceList(data) {
  return request({
    url: '/traffic-construction/installation/list',
    method: 'post',
    data
  });
}

/**
 * 装备设施添加
 * @returns {Promise}
 */
 export function addInstallation(data) {
  return request({
    url: '/traffic-construction/installation/add',
    method: 'post',
    data
  });
}

/**
 * 装备设施详细信息获取
 * @returns {Promise}
 */
 export function getDeviceDetail(params) {
  return request({
    url: '/traffic-construction/installation/getDetail',
    method: 'get',
    params
  });
}

/**
 * 装备设施删除
 * @returns {Promise}
 */
 export function deleteDeviceDetail(data) {
  return request({
    url: '/traffic-construction/installation/delete',
    method: 'post',
    data
  });
}

/**
 * 装备设施修改
 * @returns {Promise}
 */
 export function editDeviceDetail(data) {
  return request({
    url: '/traffic-construction/installation/edit',
    method: 'post',
    data
  });
}


/**
 * 导出装备设施
 * @returns {Promise}
 */
 export function exportInstallation(data) {
  return request({
    url: '/traffic-construction/installation/export',
    method: 'post',
    data,
    responseType: 'blob'
  });
}