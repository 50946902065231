<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="showAddDeviceDialog"
    width="935px"
    :close-on-click-modal="false"
    @close="handelClose"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" v-loading="dialogLoading">
      <el-row>
        <el-col :span="10">
          <el-form-item label="所属项目部" :required="!isRead" prop="projectDepartment">
            <el-select
              v-model="ruleForm.projectDepartment"
              placeholder="请选择所属项目部"
              style="width:250px"
              :disabled="isRead"
            >
              <el-option v-for="item in projectList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="设备名称" :required="!isRead" prop="name">
            <el-input
              v-model="ruleForm.name"
              style="width:250px"
              placeholder="请输入设备名称"
              :disabled="isRead"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="型号" :required="!isRead" prop="model">
            <el-input
              v-model="ruleForm.model"
              style="width:250px"
              placeholder="请输入型号"
              :disabled="isRead"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="产地" :required="!isRead" prop="originPlace">
            <el-input
              v-model="ruleForm.originPlace"
              style="width:250px"
              placeholder="请输入产地"
              :disabled="isRead"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="合格证号" :required="!isRead" prop="certificateNumber">
            <el-input
              v-model="ruleForm.certificateNumber"
              style="width:250px"
              placeholder="请输入合格证号"
              :disabled="isRead"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="检测部门（企业）" :required="!isRead" prop="testingDepartment">
            <el-input
              v-model="ruleForm.testingDepartment"
              style="width:250px"
              placeholder="请输入检测部门（企业）"
              :disabled="isRead"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="出厂日期" :required="!isRead" prop="manufactureDate">
            <el-date-picker
              style="width:250px"
              value-format="yyyy-MM-dd"
              v-model="ruleForm.manufactureDate"
              type="date"
              placeholder="请选择出厂日期"
              :disabled="isRead"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="11" :offset="2">
          <el-form-item label="使用日期" :required="!isRead" prop="useDate">
            <el-date-picker
              style="width:250px"
              value-format="yyyy-MM-dd"
              v-model="ruleForm.useDate"
              type="date"
              placeholder="请选择使用日期"
              :disabled="isRead"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div slot="footer" v-if="$check('device-manage_edit')">
      <div v-if="!isRead">
        <el-button @click="resetForm">取消</el-button>
        <el-button @click="submitForm" type="primary">确定</el-button>
      </div>
      <div v-else>
        <el-button type="danger" @click="handelDelete">删除</el-button>
        <el-button type="primary" @click="handelUpdate">修改</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { validateRules } from './validateRules.js';
import {
  addInstallation,
  getDeviceDetail,
  deleteDeviceDetail,
  editDeviceDetail
} from 'api/general-user/equip-facilities';
export default {
  name: 'AddDeviceDialog',
  mixins: [validateRules],
  props: {
    dialogTitle: {
      //标题
      type: String,
      required: true,
      default: ''
    },
    dialogVisible: {
      //显隐
      type: Boolean,
      required: true,
      default: false
    },
    isRead: {
      //是否可读(查看详情为true)
      type: Boolean,
      required: true,
      default: false
    },
    projectList: {
      type: Array,
      required: true,
      default: () => {
        [];
      }
    }
  },
  data() {
    return {
      pickerOptions: {
        //选择今天以及之前的日期
        disabledDate(time) {
          return time.getTime() > Date.now();
        }
      },
      dialogLoading: false,
      ruleForm: {},
      showAddDeviceDialog: this.dialogVisible,
      id: ''
    };
  },
  methods: {
    initFormDetail(id) {
      this.id = id;
      this.getDeviceDetail(id);
    },
    submitForm() {
      //确定
      const _that = this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (_that.id) {
            this.editDeviceDetail();
          } else {
            this.addInstallation();
          }
        }
      });
    },
    resetForm() {
      this.dialogCloseCommomFunc();
    },
    handelClose() {
      this.dialogCloseCommomFunc();
    },
    dialogCloseCommomFunc() {
      this.$emit('update:dialogVisible', false);
      this.$emit('update:isRead', false);
      this.ruleForm = {};
      this.id = '';
      this.$refs.ruleForm.resetFields();
    },
    handelDelete() {
      this.$confirm('请确认是否删除该设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.deleteDeviceDetail();
      });
    },
    handelUpdate() {
      this.$emit('update:dialogTitle', '修改设备');
      this.$emit('update:isRead', false);
    },
    //删除接口
    deleteDeviceDetail() {
      deleteDeviceDetail({ id: this.id }).then(res => {
        if (res.code === 200) {
          this.$message.success('删除成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      });
    },
    //查看详情接口
    getDeviceDetail(id) {
      this.dialogLoading = true;
      getDeviceDetail({ id })
        .then(res => {
          if (res.code === 200) {
            this.dialogLoading = false;
            this.ruleForm = res.data;
          }
        })
        .catch(() => {
          this.dialogLoading = false;
        });
    },
    //编辑接口
    editDeviceDetail() {
      editDeviceDetail(Object.assign(this.ruleForm, { id: this.id })).then(res => {
        if (res.code === 200) {
          this.$message.success('修改成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', false);
        }
      });
    },
    //新增接口
    addInstallation() {
      addInstallation(this.ruleForm).then(res => {
        if (res.code === 200) {
          this.$message.success('新增成功');
          this.$emit('update:dialogVisible', false);
          this.$emit('handel-success', true);
        }
      });
    }
  },
  watch: {
    dialogVisible(val) {
      this.showAddDeviceDialog = val;
    }
  }
};
</script>

<style scoped>
.tabel-title {
  font-weight: 700;
  font-size: 16px;
  color: #303133;
  text-align: center;
}
</style>
