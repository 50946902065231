export const validateRules = {
  data() {
    var validateProjectDepartment = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择所属机构'));
      }
      callback();
    };

    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入设备名称'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateModel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入型号'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateOriginPlace = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入产地'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateCertificateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入合格证号'));
      } else if (value.length > 50) {
        return callback(new Error('最大字符为50'));
      }
      callback();
    };
    var validateTestingDepartment = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入检测部门（企业）'));
      } else if (value.length > 20) {
        return callback(new Error('最大字符为20'));
      }
      callback();
    };
    var validateManufactureDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择出厂日期'));
      }
      callback();
    };
    var validateUseDate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请选择使用日期'));
      }
      callback();
    };
    return {
      rules: {
        projectDepartment: [{ validator: validateProjectDepartment }],
        name: [{ validator: validateName, trigger: 'blur' }],
        model: [{ validator: validateModel, trigger: 'blur' }],
        originPlace: [{ validator: validateOriginPlace, trigger: 'blur' }],
        certificateNumber: [{ validator: validateCertificateNumber, trigger: 'blur' }],
        testingDepartment: [{ validator: validateTestingDepartment, trigger: 'blur' }],
        manufactureDate: [{ validator: validateManufactureDate }],
        useDate: [{ validator: validateUseDate }]
      }
    };
  }
};
